import { ttData } from "./components/Timetable"

export const emptyTtData: ttData = {
	name: "Stundenplan",
	data: {
		wdays: [ "Mo.", "Di.", "Mi.", "Do.", "Fr." ],
		times: [ {}, {}, {}, {}, {}, {}, {}, {}, {} ],
		days: [
			[
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				}
			],[
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				}
			],[
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				}
			],[
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				}
			],[
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				},
				{
					subject: "",
					teacher: "",
					room: "",
					color: "",
					fcolor: ""
				}
			]
		]
	}
}
