import { emptyTtData } from "./emptyTtData"
import { ttData } from "./components/Timetable"

export function addNewTt(id?: string) {
	if (!localStorage["tts"]) {
		localStorage.setItem("tts", JSON.stringify([]))
	}
	const tts: Array<string> = JSON.parse(localStorage.getItem("tts") || "[]")
	let newId = id ? "ttData-"+id : "ttData-"+(tts.length+1)
	if (localStorage[newId]) {
		newId = newId+"-new"
	}
	localStorage.setItem(newId, JSON.stringify(emptyTtData))
	tts.push(newId)
	localStorage.setItem("tts", JSON.stringify(tts))

	return newId.substr(7)
}
export function saveTt(id: string, ttData: ttData) {
	if (!localStorage["tts"]) {
		localStorage.setItem("tts", JSON.stringify([]))
	}
	const tts: Array<string> = JSON.parse(localStorage.getItem("tts") || "[]")
	const lsid = `ttData-${id}` // lsid: Local Storage ID
	if (!tts.includes(lsid)) {
		tts.push(lsid)
	}
	localStorage.setItem("tts", JSON.stringify(tts))
	localStorage.setItem(lsid, JSON.stringify(ttData))
}
