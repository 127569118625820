import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from "@material-ui/core/Drawer";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from "@material-ui/core/Fab";
import DoneIcon from "@material-ui/icons/Done"
import TextField from "@material-ui/core/TextField";
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import "./Timetable.css";
import { addNewTt, saveTt } from "../ttPersistance";
import { TTJustus } from "../TTJustus";
import { emptyTtData } from "../emptyTtData";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export interface lesson {
	subject: string | null,
	teacher: string | null,
	room: string | null,
	color: string | null,
	fcolor?: string | null
}

export interface ttData {
	name: string,
	data: {
		wdays: Array<string>
		days: Array<Array<lesson>>
		times: Array<{ from?: string, to?: string }>
	}
}

export default function Timetable() {

	// set Timetable ID
	let { ttId } = useParams<{ttId: string}>()

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				flexGrow: 1,
			},
			menuButton: {
				marginRight: theme.spacing(2),
			},
			title: {
				flexGrow: 1,
			},
			formControl: {
				margin: 0,
			},
		}),
	);
	const classes = useStyles();

	// const exampleTtData = TTJustus
	const loadedTtData = JSON.parse(window.localStorage.getItem(`ttData-${ttId}`) || "false") || emptyTtData

	// eslint-disable-next-line
	const [ttData, setTtData] = useState<ttData>(loadedTtData)
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [editSubject, setEditSubject] = useState("")
	const [editTeacher, setEditTeacher] = useState("")
	const [editRoom, setEditRoom] = useState("")
	const [editColor, setEditColor] = useState("")
	const [editPosDay, setEditPosDay] = useState(0)
	const [editPosLesson, setEditPosLesson] = useState(0)
	const [timeDiaOpen, setTimeDiaOpen] = useState(false)
	const [editTimeFrom, setEditTimeFrom] = useState<Date | null>(new Date())
	const [editTimeTo, setEditTimeTo] = useState<Date | null>(new Date())

	const colorPalette = [
		{
			name: "Grau",
			color: "#303030",
			fcolor: "#ffffff"
		},
		{
			name: "Gelb",
			color: "#ffeb3c",
			fcolor: "#000000"
		},
		{
			name: "Grün",
			color: "#4cb050",
			fcolor: "#ffffff"
		},
		{
			name: "Lila",
			color: "#9c28b1",
			fcolor: "#ffffff"
		},
		{
			name: "Rot",
			color: "#f44236",
			fcolor: "#ffffff"
		},
		{
			name: "Schwarz",
			color: "#000000",
			fcolor: "#ffffff"
		},
		{
			name: "Orange",
			color: "#ff9700",
			fcolor: "#ffffff"
		},
		{
			name: "Blau",
			color: "#3f51b5",
			fcolor: "#ffffff"
		},
		{
			name: "Hellgrün",
			color: "#8bc24a",
			fcolor: "#ffffff"
		},
		{
			name: "Weiß",
			color: "#ffffff",
			fcolor: "#000000"
		},
	]

	/**
	 * Get the hex color code from a word
	 * @param {string} color - The color as a word (yellow, green, ...)
	 * @returns {string} Hex color code.
	 */
	function getBGCCode(color: string | null): string | null {
		switch (color) {
			case "yellow":
				return "#ffeb3c"
			case "green":
				return "#4cb050"
			case "purple":
				return "#9c28b1"
			case "red":
				return "#f44236"
			case "black":
				return "#000000"
			case "orange":
				return "#ff9700"
			case "blue":
				return "#3f51b5"
			case "lightgreen":
				return "#8bc24a"
			case "white":
				return "#ffffff"
			default:
				// return "#303030"
				return null
		}
	}

	function getCCode(color: string): string {
		let fcolor: string = ""
		colorPalette.forEach((v) => {
			if (v.color === color) {
				fcolor = v.fcolor
			}
		})
		if (fcolor === "") {
			return calcCCode(color)
		}
		return fcolor
	}

	/**
	 * 
	 * @param {string} hexcolor - The BG color as Hex
	 * @returns {string} - The appropriate font color so that the contrast is roughly sufficiant.
	 */
	// eslint-disable-next-line
	function calcCCode(hexcolor: string): string {
		return (
			(
				parseInt(hexcolor.substr(1), 16) > 0xffffff/2
			) ? '#000000' : '#ffffff'
		)
	}

	/**
	 * 
	 * @param {number} weekDay - The weekday index (i)
	 * @param {number} lesson - The lesson index (j)
	 */
	function editLesson(weekDay: number, lesson: number) {
		setEditPosDay(weekDay)
		setEditPosLesson(lesson)
		setEditSubject(ttData.data.days[weekDay][lesson].subject || "")
		setEditTeacher(ttData.data.days[weekDay][lesson].teacher || "")
		setEditRoom(ttData.data.days[weekDay][lesson].room || "")
		setEditColor(ttData.data.days[weekDay][lesson].color || "#303030")
		setDrawerOpen(true)
	}

	/**
	 * 
	 * @param {number} weekDay - The weekday index (i)
	 * @param {number} lesson - The lesson index (j)
	 * @param {lesson} data - The lesson data
	 */
	function saveEdit(weekDay: number, lesson: number, data: lesson) {
		const newTT = [...ttData.data.days]
		newTT[weekDay].splice(lesson, 1, data)
		setTtData({
			...ttData,
			data: {
				...ttData.data,
				days: newTT
			}
		})
		saveTt(ttId, ttData)
		setDrawerOpen(false)
	}

	return (
		<div>
			<AppBar position="sticky" color="inherit">
				<Toolbar>
					<Link to="/">
						<IconButton edge="start" className={classes.menuButton}>
							<ArrowBackIcon />
						</IconButton>
					</Link>
					<Typography variant="h6">{ttData.name}</Typography>
				</Toolbar>
			</AppBar>
			<Drawer anchor="bottom" open={drawerOpen} onClose={()=>setDrawerOpen(false)} style={{overflowY: "inherit"}}>
				<div className="Timetable-tt-drawer">
					<div className="Timetable-tt-drawer-fab-div">
						{/* Add-button */}
						<Fab color="primary" aria-label="edit" onClick={() => {
							saveEdit(editPosDay, editPosLesson, {
								subject: editSubject,
								teacher: editTeacher,
								room: editRoom,
								color: editColor,
								fcolor: getCCode(editColor)
							})
						}}>
							<DoneIcon />
						</Fab>
					</div>
					<div className="Timetable-tt-drawer-input-div">
						{/* Input for subject */}
						<TextField
							// id="standard-basic"
							variant="filled"
							color="secondary"
							label="Fach"
							style={{width: "70%"}}
							defaultValue={editSubject}
							onChange={(e) => {
								setEditSubject(e.target.value)
							}}
						/>
					</div>
					<div className="Timetable-tt-drawer-input-div">
						{/* Input for teacher */}
						<TextField
							// id="standard-basic"
							variant="filled"
							color="secondary"
							label="Lehrer"
							style={{width: "70%"}}
							defaultValue={editTeacher}
							onChange={(e) => {
								setEditTeacher(e.target.value)
							}}
						/>
					</div>
					<div className="Timetable-tt-drawer-input-div">
						{/* Input for room */}
						<TextField
							// id="standard-basic"
							variant="filled"
							color="secondary"
							label="Raum"
							style={{width: "70%"}}
							defaultValue={editRoom}
							onChange={(e) => {
								setEditRoom(e.target.value)
							}}
						/>
					</div>
					<div className="Timetable-tt-drawer-input-div" style={{width: "70%"}}>
						<FormControl fullWidth variant="filled" color="secondary" className={classes.formControl}>
							<InputLabel id="farbe-select-label">Farbe</InputLabel>
							<Select
								labelId="farbe-select-label"
								id="farbe-select"
								
								defaultValue={editColor}
								onChange={(e) => {
									if (typeof e.target.value === "string") {
										setEditColor(e.target.value)
									} else {
										setEditColor("#303030")
									}
								}}
								label="Farbe"
							>
								{colorPalette.map((v) => {
									return (
										<MenuItem
											value={v.color}
											style={{
												backgroundColor: v.color,
												color: v.fcolor
											}}
										>{v.name}</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</div>
				</div>
			</Drawer>
			<Dialog open={timeDiaOpen} onClose={() => setTimeDiaOpen(false)} aria-labelledby="time-dialog-title">
				<DialogTitle id="time-dialog-title">Zeiten einstellen</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>
						Stelle hier die Zeiten ein.
					</DialogContentText> */}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<TimePicker
							clearable
							ampm={false}
							label="Von"
							value={editTimeFrom}
							onChange={(date: Date | null) => {}}
						/>
						<TimePicker
							clearable
							ampm={false}
							label="Bis"
							value={editTimeTo}
							onChange={(date: Date | null) => {}}
						/>
					</MuiPickersUtilsProvider>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setTimeDiaOpen(false)} color="primary">
						Abbrechen
					</Button>
					<Button onClick={() => setTimeDiaOpen(false)} color="primary">
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
			<div className="Timetable-tt-div">
				<div className="Timetable-tt-row">
					<div className="Timetable-tt-col">
						<ButtonBase className="Timetable-tt-button Timetable-tt-button-wd">
							<div className="Timetable-tt-button-invis">S</div>
						</ButtonBase>
						{/* Function for generating the number of lessons. TODO: make this dynamic and add begin and end time. */}
						{ttData.data.times.map((v, i) => {
							return (
								<ButtonBase
									className="Timetable-tt-button"
									key={i}
									onClick={(e) => {
										// setTimeDiaOpen(true)
									}}
								>
									{!(v.from || v.to) ? <div>{i+1}</div> : null}
									{v.from ? <div>{v.from}</div> : null}
									{v.to ? <div>{v.to}</div> : null}
								</ButtonBase>
							)
						})}
					</div>
				</div>
				{/* Iterate through each day */}
				{ttData.data.days.map((el, i) => {
					return (
						<div
							className="Timetable-tt-row"
							key={`${ttData.data.wdays[i]}-col`}
						>
							<div className="Timetable-tt-col">
								<ButtonBase className="Timetable-tt-button Timetable-tt-button-wd">
									<div>{ttData.data.wdays[i]}</div>
								</ButtonBase>
								
								{/* Iterate through each lesson on a given day */}
								{el.map((el, j)=>{
									return (
										<ButtonBase
											className="Timetable-tt-button"
											style={
												{
													backgroundColor: (
														getBGCCode(el.color) || el.color || "#303030"
													),
													color: (
														// getCCode(getBGCCode(el.color))
														el.fcolor ? el.fcolor : "#ffffff"
													)
												}
											} 
											key={`${ttData.data.wdays[i]}-${j+1}`}
											onClick={(e) => {
												// i: weekDay; j: lesson
												// console.log(i, j)
												editLesson(i, j)
											}}
										>
											<div>{el.subject}</div>
											<div>{el.teacher}</div>
											<div>{el.room}</div>
										</ButtonBase>
									)
								})}
								
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
