import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link, useHistory } from "react-router-dom";
import { addNewTt } from "../ttPersistance";
import { ttData } from "./Timetable";

function ListItemLink(props: any) {
	const { icon, primary, to } = props;

	const CustomLink = React.useMemo(
		() =>
			React.forwardRef((linkProps, ref) => (
				<Link /*ref={ref}*/ to={to} {...linkProps} />
			)),
		[to],
	)

	return (
		<li>
			<ListItem button component={CustomLink}>
				{/* <ListItemIcon>{icon}</ListItemIcon> */}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	)
}

export default function TimetableList() {
	const history = useHistory()

	const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
	const [openRename, setOpenRename] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [targetTt, setTargetTt] = useState<null|string>(null)
	const [newName, setNewName] = useState<string>("")

	function getTtList() {
		if (!localStorage["tts"]) {
			localStorage.setItem("tts", JSON.stringify([]))
		}
		const tts: Array<string> = JSON.parse(localStorage.getItem("tts") || "[]")

		return tts.map((item) => {
			if (!localStorage[item]) return {name: null, id: null}
			return (
				{
					name: JSON.parse(localStorage.getItem(item) || "").name,
					id: item.substr(7)
				}
			)
		})
	}

	return (
		<div>
			<AppBar position="fixed" color="inherit">
				<Toolbar>
					<Typography variant="h6">Stundenpläne</Typography>
				</Toolbar>
			</AppBar>
			<div style={{marginTop: "64px"}}>
				{/* <Button color="primary" onClick={()=>{
					localStorage.clear()
				}}>Nuke</Button> */}
				<Menu
					id="ttMenu"
					anchorEl={menuEl}
					keepMounted
					open={Boolean(menuEl)}
					onClose={() => {
						setMenuEl(null)
					}}
				>
					<MenuItem onClick={() => {
						setMenuEl(null)
						setOpenRename(true)
					}}>Umbenennen</MenuItem>
					<MenuItem onClick={() => {
						setMenuEl(null)
						setOpenDelete(true)
					}}>Löschen</MenuItem>
				</Menu>
				<Dialog open={openRename} onClose={() => setOpenRename(false)} aria-labelledby="rename-dialog-title">
					<DialogTitle id="rename-dialog-title">Umbenennen</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Gib hier den neuen Namen für den Stundenplan ein.
						</DialogContentText>
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label="Neuer Name"
							type="text"
							onChange={(e) => {
								setNewName(e.target.value)
							}}
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenRename(false)} color="primary">
							Abbrechen
						</Button>
						<Button onClick={() => {
							const tmpTtData: ttData = JSON.parse(localStorage.getItem("ttData-"+targetTt) || "{}")
							tmpTtData.name = newName
							localStorage.setItem("ttData-"+targetTt, JSON.stringify(tmpTtData))
							setOpenRename(false)
						}} color="primary">
							Umbenennen
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={openDelete}
					onClose={() => setOpenDelete(false)}
					aria-labelledby="delete-dialog-title"
					aria-describedby="delete-dialog-description"
				>
					<DialogTitle id="delete-dialog-title">Stundenplan löschen?</DialogTitle>
					<DialogContent>
						<DialogContentText id="delete-dialog-description">
							Willst du diesen Stundenplan wirklich löschen?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenDelete(false)} color="primary">
							Abbrechen
						</Button>
						<Button onClick={() => {
							// Get timetables (tmpTts)
							let tmpTts: Array<string> = JSON.parse(localStorage.getItem("tts") || "[]")

							// Actually remove timetable
							localStorage.removeItem("ttData-"+targetTt)

							console.log("tmpTts before mod: ", tmpTts)

							// Modify tts array
							if (tmpTts.length === 1) { // Does not properly delete if array only contains one element
								tmpTts = []            // So we'll just manually set it to an empty array
							} else {
								tmpTts.splice(1, tmpTts.indexOf("ttData-"+targetTt)) // Else just do your thing
							}

							console.log("tmpTts after mod: ", tmpTts)

							// repopulate tts array
							localStorage.setItem("tts", JSON.stringify(tmpTts))

							// Close dialog
							setOpenDelete(false)
						}} color="primary" autoFocus>
							Löschen
						</Button>
					</DialogActions>
				</Dialog>
				<List>
					{getTtList().map((item) => {
						if (item) {
							return (
								<ListItem button onClick={() => {history.push(`/timetable/${(item.id || "")}`)}}>
									<ListItemText
										primary={item.name || ""}
										// secondary=""
									/>
									<ListItemSecondaryAction>
									<IconButton edge="end" aria-label="more" onClick={(e) => {
										setMenuEl(e.currentTarget)
										setTargetTt(item.id)
									}}>
										<MoreVertIcon />
									</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							)
						} else return null
					})}
				</List>
				<Fab
					color="primary"
					aria-label="add"
					style={{position: "absolute", right: "16px", bottom: "16px"}}
					onClick={() => {
						history.push(`/timetable/${addNewTt()}`)
					}}
				>
					<AddIcon />
				</Fab>
			</div>
		</div>
	)
}
