import React from "react";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Button from "@material-ui/core/Button";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	// Link,
	// Redirect
} from "react-router-dom";
import Timetable from "./components/Timetable";
import TimetableList from "./components/TimetableList";

function App() {
	const prefersDarkMode = true // useMediaQuery('(prefers-color-scheme: dark)');
	const theme = React.useMemo(
		() =>
			createTheme({
				overrides: {
					MuiDrawer: {
						paper: {
							overflowY: "unset"
						}
					}
				},
				palette: {
					type: prefersDarkMode ? 'dark' : 'light',
				},
			}),
		[prefersDarkMode],
	);

	return (
		<Router>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Switch>
					<Route path="/timetable/:ttId">
						<Timetable />
					</Route>
					<Route path="/">
						<TimetableList />
					</Route>
				</Switch>
		</ThemeProvider>
		</Router>
	);
}

export default App;
